// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-list-js": () => import("./../../../src/templates/BlogPostList.js" /* webpackChunkName: "component---src-templates-blog-post-list-js" */),
  "component---src-templates-blog-post-single-js": () => import("./../../../src/templates/BlogPostSingle.js" /* webpackChunkName: "component---src-templates-blog-post-single-js" */),
  "component---src-templates-page-single-js": () => import("./../../../src/templates/PageSingle.js" /* webpackChunkName: "component---src-templates-page-single-js" */),
  "component---src-templates-product-list-js": () => import("./../../../src/templates/ProductList.js" /* webpackChunkName: "component---src-templates-product-list-js" */),
  "component---src-templates-product-single-js": () => import("./../../../src/templates/ProductSingle.js" /* webpackChunkName: "component---src-templates-product-single-js" */)
}

